import { Component } from '@angular/core';

@Component({
  selector: 'app-ingest-skill-graph',
  templateUrl: './ingest-skill-graph.component.html',
  styleUrls: ['./ingest-skill-graph.component.scss']
})
export class IngestSkillGraphComponent {

  constructor() { }
}
