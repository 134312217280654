import { Component } from '@angular/core';

@Component({
  selector: 'app-datasource-datainventory',
  templateUrl: './datasource-datainventory.component.html',
  styleUrls: ['./datasource-datainventory.component.scss']
})
export class DatasourceDataInventoryComponent {

}
