import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LstAuthGuard, LstRouterModule } from '@lst/lst-auth';
import { ErrorComponent } from './pages/error/error.component';
import { SecurityRdsComponent } from './pages/security-rds/security-rds.component';
import { SecurityCodeqlComponent } from './pages/security-codeql/security-codeql.component';
import { DatasourceCodeqlComponent } from './pages/datasource-codeql/datasource-codeql.component';
import { DatasourceEC2AgentsInventoryComponent } from './pages/datasource-ec2agentsinventory/datasource-ec2agentsinventory.component';
import { DatasourceDataInventoryComponent } from './pages/datasource-datainventory/datasource-datainventory.component';
import { IngestSkillGraphComponent } from './pages/ingest-skill-graph/ingest-skill-graph.component';
import { ProgramsComponent } from './pages/programs/programs.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'security-rds',
        pathMatch: 'full',
    },
    {
        path: 'security-rds',
        component: SecurityRdsComponent,
    },
    {
        path: 'security-codeql',
        component: SecurityCodeqlComponent,
    },
    {
        path: 'datasource-codeql',
        component: DatasourceCodeqlComponent,
    },
    {
        path: 'datasource-ec2agentsinventory',
        component: DatasourceEC2AgentsInventoryComponent,
    },
    {
        path: 'datasource-datainventory',
        component: DatasourceDataInventoryComponent,
    },
    {
        path: 'programs',
        component: ProgramsComponent,
        canActivate: [LstAuthGuard]
    },
    {
        path: 'ingest-skill-graph',
        component: IngestSkillGraphComponent,
        canActivate: [LstAuthGuard]
    },
    {
        path: 'error',
        component: ErrorComponent,
    },
    {
        path: '**',
        redirectTo: 'security',
        pathMatch: 'full',
    },

];

@NgModule({
    imports: [LstRouterModule.getRoutes(), RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
