import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LstAuthService } from '@lst/lst-auth';
import { ReplaySubject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { SavvyAdminService } from './core/savvy-admin/savvy-admin.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    isErrorRoute = false;

    constructor(
        private readonly route: ActivatedRoute
    ) {
        this.route.url
            .pipe(takeUntil(this.destroyed$))
            .subscribe((urlSegment) => {
                this.isErrorRoute =
                    urlSegment[0] && urlSegment[0].path === 'error';
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

}
