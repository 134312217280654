import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { PROFILE_TOKEN } from '../model/savvy-admin.model';
import { StorageService } from '../services/storage-service/storage.service';
import { getSyncedPrograms_getSyncedPrograms as SyncedPrograms } from './../../../../schema/graphql-types';
import { SavvyAdminDataService } from './savvy-admin.data.service';

@Injectable({
    providedIn: 'root',
})
export class SavvyAdminService {
    private syncedPrograms: SyncedPrograms[] = [];
    private readonly getSyncedPrograms = new BehaviorSubject<SyncedPrograms[]>(this.syncedPrograms);
    private readonly isLoading = new BehaviorSubject<boolean>(true);

    getSyncedPrograms$ = this.getSyncedPrograms.asObservable();
    isLoading$ = this.isLoading.asObservable();

    constructor(
        private readonly savvyAdminDataService: SavvyAdminDataService,
        private readonly sessionStorage: StorageService
    ) {}

    loadSyncedPrograms(): void {
        if (!this.syncedPrograms.length) {
            this.isLoading.next(true);
            this.savvyAdminDataService
                .getSyncedPrograms()
                .pipe(first())
                .subscribe((data) => {
                    this.isLoading.next(false);
                    this.syncedPrograms = data.getSyncedPrograms;
                    this.getSyncedPrograms.next(this.syncedPrograms);
                });
        }
    }
}
