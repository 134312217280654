import { NgModule } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache, ApolloLink } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { from } from '@apollo/client/link/core';
import { HttpClientModule } from '@angular/common/http';
import { environment } from './../../../../src/environments/environment';
import { LstUserService } from '@lst/lst-auth';
import { Router } from '@angular/router';

@NgModule({
    exports: [HttpClientModule],
})
export class GraphqlModule {
    constructor(
        private readonly apollo: Apollo,
        private readonly httpLink: HttpLink,
        private readonly router: Router,
        private readonly lstUserService: LstUserService
    ) {
        this.apollo.create({
            link: from([this.authMiddleware(), this.errorAfterware(), this.http()]),
            cache: new InMemoryCache(),
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
                mutate: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
            },
        });
    }

    private authMiddleware(): ApolloLink {
        return setContext((_, { headers }) => {
            // get the rbs token from session storage if it exists
            const rbsToken = this.lstUserService.getRbsToken();
            // return the headers to the context so httpLink can read them
            return {
                headers: {
                    ...headers,
                    authorization: rbsToken ? `Bearer ${rbsToken}` : '',
                },
            };
        });
    }

    private http(): ApolloLink {
        const uri = `${environment.backendForFrontend.url}${environment.backendForFrontend.apiPath}`;
        return this.httpLink.create({ uri });
    }

    private errorAfterware(): ApolloLink {
        return onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors) {
                graphQLErrors.forEach(({ message, locations, path }) =>
                    console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
                );
            }
            if (networkError) {
                console.log(`[Network error]: ${networkError}`);
            }
            this.router.navigate(['error']);
        });
    }
}
