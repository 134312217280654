import { Component } from '@angular/core';

@Component({
  selector: 'app-datasource-codeql',
  templateUrl: './datasource-codeql.component.html',
  styleUrls: ['./datasource-codeql.component.scss']
})
export class DatasourceCodeqlComponent {

}
