import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SavvyAdminService } from 'src/app/core/savvy-admin/savvy-admin.service';
import { getSyncedPrograms_getSyncedPrograms as SyncedPrograms } from './../../../../schema/graphql-types';
import { getSyncedPrograms_getSyncedPrograms_filter as FilterableSyncedPrograms } from 'src/app/types/programs.components.types';

@Component({
    selector: 'app-programs',
    templateUrl: './programs.component.html',
    styleUrls: ['./programs.component.scss'],
})
export class ProgramsComponent implements AfterViewInit, OnInit, OnDestroy {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    displayedColumns: string[] = ['name', 'product', 'isActive', 'activeSkillGraphId', 'dateCreated', 'dateUpdated', 'action'];
    syncedPrograms = new MatTableDataSource<SyncedPrograms>();
    defaultSyncedPrograms: SyncedPrograms[] = [];
    filterableSyncedPrograms: FilterableSyncedPrograms[] = [];

    constructor(private readonly savvyAdminService: SavvyAdminService) { }

    @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
    @ViewChild(MatSort) sort: MatSort | undefined;

    ngOnInit() {
        this.savvyAdminService.getSyncedPrograms$.pipe(takeUntil(this.destroyed$)).subscribe((syncedPrograms) => {
            this.syncedPrograms = new MatTableDataSource<SyncedPrograms>(syncedPrograms);
            this.syncedPrograms.paginator = this.paginator as MatPaginator;
            this.syncedPrograms.sort = this.sort as MatSort;
            this.syncedPrograms.data = this.sortSyncedPrograms(this.syncedPrograms.data);
            this.defaultSyncedPrograms = this.syncedPrograms.data;
        });
        this.savvyAdminService.loadSyncedPrograms();
    }

    ngAfterViewInit() {
        this.syncedPrograms.paginator = this.paginator as MatPaginator;
        this.syncedPrograms.sort = this.sort as MatSort;
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    parseDate(isoDateString: string): string {
        return new Date(isoDateString).toLocaleDateString();
    }

    applyFilter(event: any): void {
        const filterValue = event.target.value;
        this.filterableSyncedPrograms = this.defaultSyncedPrograms;
        this.convertDataToString();

        const newFilteredPrograms = new MatTableDataSource(this.filterableSyncedPrograms);
        newFilteredPrograms.filter = filterValue.toLowerCase();
        const filteredIds = this.getSyncedProgramsFilteredIds(newFilteredPrograms.filteredData);
        const filteredSyncedProgramsById = this.defaultSyncedPrograms.slice().filter(data => filteredIds.includes(data.id));

        const filteredSyncedPrograms = new MatTableDataSource(filteredSyncedProgramsById);
        filteredSyncedPrograms.paginator = this.paginator as MatPaginator;
        filteredSyncedPrograms.sort = this.sort as MatSort;
        this.syncedPrograms = filteredSyncedPrograms;
    }

    sortSyncedPrograms(syncedPrograms: SyncedPrograms[]) {
        const sortedSyncedPrograms = syncedPrograms.sort((firstProgram, secondProgram) => {
            const secondProgramCreatedDate = (new Date(secondProgram.dateCreated)).valueOf();
            const firstProgramCreatedDate = (new Date(firstProgram.dateCreated)).valueOf();
            return firstProgramCreatedDate - secondProgramCreatedDate;
        })
        return sortedSyncedPrograms;
    }

    convertDataToString() {
        this.filterableSyncedPrograms.forEach((data) => {
            data.isActive = data.isActive ? "Yes" : "No";
            if (data.activeSkillGraphId) {
                data.activeSkillGraphId = data.activeSkillGraphId.toString();
            }
            data.dateCreated = this.parseDate(data.dateCreated);
            data.dateUpdated = this.parseDate(data.dateUpdated);
        });
    }

    getSyncedProgramsFilteredIds(filteredData: FilterableSyncedPrograms[]): number[] {
        let filteredIds = filteredData.map(data => data.id);
        return filteredIds;
    }
}
