import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { registerProgram } from 'src/app/graphql/mutations/registerProgram.mutation';
import { getProgramSkillGraphs } from 'src/app/graphql/queries/getProgramSkillGraph.query';
import { getSyncedPrograms } from 'src/app/graphql/queries/getSyncedPrograms.query';
import {
    getSyncedPrograms as GetSyncedProgramsResponse,
    getProgramSkillGraphsVariables as GetProgramSkillGraphsInput,
    getProgramSkillGraphs as GetProgramSkillGraphsResponse,
    registerProgramVariables as RegisterProgramInput,
    registerProgram as RegisterProgramResponse,
} from './../../../../schema/graphql-types';

@Injectable({
    providedIn: 'root',
})
export class SavvyAdminDataService {
    constructor(private readonly apollo: Apollo) {}

    getSyncedPrograms(): Observable<GetSyncedProgramsResponse> {
        return this.apollo
            .query<GetSyncedProgramsResponse>({
                query: getSyncedPrograms,
            })
            .pipe(map(({ data }) => data));
    }

    getProgramSkillGraphs(input: GetProgramSkillGraphsInput): Observable<GetProgramSkillGraphsResponse> {
        return this.apollo
            .query<GetProgramSkillGraphsResponse>({
                query: getProgramSkillGraphs,
                variables: input,
            })
            .pipe(map(({ data }) => data));
    }

    registerProgram(input: RegisterProgramInput): Observable<RegisterProgramResponse | null | undefined> {
        return this.apollo
            .mutate<RegisterProgramResponse>({
                mutation: registerProgram,
                variables: input,
            })
            .pipe(map(({ data }) => data));
    }
}
