<app-loader></app-loader>
<div class="wrapper">
    <div class="top-nav" *ngIf="!isErrorRoute">
        <mat-toolbar color="primary">
            <span>{{ 'app-name' | translate }}</span>
        </mat-toolbar>
    </div>
    <mat-drawer-container class="main-container">
        <mat-drawer mode="side" class="container-sidenav" opened>
            <ul>
                <li matRipple>
                    <a routerLinkActive="active" [routerLink]="'/security-rds'">{{ 'navigation.security-rds' | translate }}</a>
                </li>
                <li matRipple>
                    <a routerLinkActive="active" [routerLink]="'/security-codeql'">{{ 'navigation.security-codeql' | translate }}</a>
                </li>
                <li matRipple>
                    <a routerLinkActive="active" [routerLink]="'/datasource-codeql'">{{ 'navigation.datasource-codeql' | translate }}</a>
                </li>
                <li matRipple>
                    <a routerLinkActive="active" [routerLink]="'/datasource-ec2agentsinventory'">{{ 'navigation.datasource-ec2agentsinventory' | translate }}</a>
                </li>
                <li matRipple>
                    <a routerLinkActive="active" [routerLink]="'/datasource-datainventory'">{{ 'navigation.datasource-datainventory' | translate }}</a>
                </li>
            </ul>
        </mat-drawer>
        <mat-drawer-content class="container-body">
            <router-outlet></router-outlet>
        </mat-drawer-content>
    </mat-drawer-container>
</div>
