import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RBS_TOKEN } from '../../model/savvy-admin.model';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor() {}

    getItem(key: string): string | null {
        if (sessionStorage.getItem(this.normalized(key)) !== 'undefined') {
            return sessionStorage.getItem(this.normalized(key));
        }
        return null;
    }

    setItem(key: string, value: any): void {
        sessionStorage.setItem(this.normalized(key), JSON.stringify(value));
    }

    removeItem(key: string): void {
        sessionStorage.removeItem(this.normalized(key));
    }

    clear(): void {
        Object.keys(sessionStorage)
            .filter((key) => key.includes(environment.appId))
            .forEach((item) => sessionStorage.removeItem(item));
    }

    private normalized(key: string): string {
        return `${environment.appId}-${key}`;
    }

    isValidItem(key: string): boolean {
        return sessionStorage.getItem(this.normalized(key)) !== 'undefined';
    }

    getRealizeRbsToken(): string | null {
        return sessionStorage.getItem(RBS_TOKEN);
    }

    clearRealizeRbsToken(): void {
        sessionStorage.removeItem(RBS_TOKEN);
    }
}
