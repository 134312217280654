import { Component } from '@angular/core';

@Component({
  selector: 'app-security-rds',
  templateUrl: './security-rds.component.html',
  styleUrls: ['./security-rds.component.scss']
})
export class SecurityRdsComponent {

}
