import { Component } from '@angular/core';

@Component({
  selector: 'app-datasource-ec2agentsinventory',
  templateUrl: './datasource-ec2agentsinventory.component.html',
  styleUrls: ['./datasource-ec2agentsinventory.component.scss']
})
export class DatasourceEC2AgentsInventoryComponent {

}
