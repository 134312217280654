import { Component, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { SavvyAdminService } from 'src/app/core/savvy-admin/savvy-admin.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnDestroy {
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    isLoading: boolean = true;

    // TODO/FIXME: "LoaderComponent" is checking whether savvyAdminService is loaded or not,
    // This is no longer needed for the pages we are developping, since we don't have BFE.
    // There is a future cleanup story CI-1558, and until we do, we will keep the code,
    // so that we can see working code.
     constructor(private readonly savvyAdminService: SavvyAdminService) {
        this.isLoading = false;
        // this.savvyAdminService.isLoading$
        //     .pipe(takeUntil(this.destroyed$))
        //     .subscribe((isLoaded) => {
        //         this.isLoading = isLoaded;
        //     });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
