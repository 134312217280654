<app-loader></app-loader>
<div class="programs-wrapper">
    <div class="programs-header">
        <input
            type="text"
            class="mat-elevation-z2"
            placeholder="Search Programs"
            (keyup)="applyFilter($event)"
        />
        <button mat-raised-button color="primary">{{ 'programs.register-program-button' | translate }}</button>
    </div>
    <div class="programs-body">
        <div class="mat-elevation-z2">
            <table mat-table matSort [dataSource]="syncedPrograms">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'programs.table.name' | translate }}</th>
                    <td mat-cell *matCellDef="let program">{{ program.name }}</td>
                </ng-container>

                <ng-container matColumnDef="product">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'programs.table.product' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let program">{{ program.product }}</td>
                </ng-container>

                <ng-container matColumnDef="isActive">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'programs.table.active' | translate }}</th>
                    <td mat-cell *matCellDef="let program">{{ program.isActive }}</td>
                </ng-container>

                <ng-container matColumnDef="activeSkillGraphId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'programs.table.skill-graph-id' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let program">{{ program.activeSkillGraphId || '-' }}</td>
                </ng-container>

                <ng-container matColumnDef="dateCreated">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'programs.table.created-date' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let program">{{ program.dateCreated }}</td>
                </ng-container>

                <ng-container matColumnDef="dateUpdated">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'programs.table.updated-date' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let program">{{ program.dateUpdated }}</td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'programs.table.action' | translate }}</th>
                    <td mat-cell *matCellDef="let program">
                        <button mat-icon-button>
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons> </mat-paginator>
        </div>
    </div>
</div>
