import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SecurityRdsComponent } from './pages/security-rds/security-rds.component';
import { SecurityCodeqlComponent } from './pages/security-codeql/security-codeql.component';
import { DatasourceCodeqlComponent } from './pages/datasource-codeql/datasource-codeql.component';
import { DatasourceEC2AgentsInventoryComponent } from './pages/datasource-ec2agentsinventory/datasource-ec2agentsinventory.component';
import { DatasourceDataInventoryComponent } from './pages/datasource-datainventory/datasource-datainventory.component';
import { ProgramsComponent } from './pages/programs/programs.component';
import { IngestSkillGraphComponent } from './pages/ingest-skill-graph/ingest-skill-graph.component';
import { ErrorComponent } from './pages/error/error.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { LstAuthModule, LstAuthModuleOptions } from '@lst/lst-auth';
import { environment } from 'src/environments/environment';
import { Apollo } from 'apollo-angular';

const lstModuleOptions: LstAuthModuleOptions = {
    appId: environment.appId,
    authGatewayBaseUrl: environment.authGatewayUrl,
    authContextId: environment.authContextId,
    authGatewayClientId: environment.rbsConfig.clientId,
    rbsTokenScope: environment.rbsConfig.scope,
    ssoBaseUrl: environment.ssoUrl,
    rbsTokenGrantType: environment.rbsConfig.grant_type,
    loginQueryParams: environment.ssoLoginQueryParams,
    errorRoute: environment.errorRoute,
    authCallbackRoute: environment.authCallbackRoute,
};

@NgModule({
    declarations: [
        AppComponent,
        SecurityRdsComponent,
        SecurityCodeqlComponent,
        ProgramsComponent,
        IngestSkillGraphComponent,
        ErrorComponent,
        DatasourceCodeqlComponent,
        DatasourceEC2AgentsInventoryComponent,
        DatasourceDataInventoryComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CoreModule,
        SharedModule,
        LstAuthModule.forRoot(lstModuleOptions),
    ],
    providers: [
        Apollo
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
